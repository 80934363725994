<template>
  <div class="home-container">
    <full-page :options="options" ref="fullpage">
      <!-- 首页轮播图-start -->
      <div class="section">
        <r-header></r-header>
        <swiper :options="swiperOption" class="home-swiper">
          <swiper-slide v-for="item in adverts" :key="item.id" >
            <img :src="item.img" alt="" @click="toDetail(item)">
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <!-- 首页轮播图-end -->
      <!-- 新闻资讯-start -->
      <div class="section">
        <div class="box2">
          <div class="news-box" v-for="(item, index) in newsList" :key="index">
            <div class="news-box-title">
              {{ item.title }}
            </div>
            <div class="news-list">
              <div :class="{ 'news-item': true, 'a-line': item.name == 'trends' }" v-for="(news, i) in item.list" @click="toNews(news)"
                :key="news.id">
                <div class="title-img" v-if="item.name == 'trends' && i == 0">
                  <img :src="news.titleImg" alt="">
                </div>
                <div class="news-title">
                  {{ news.title }}
                </div>
                <div v-if="item.name != 'trends'" class="news-description">
                  {{ news.description }}
                </div>
                <div class="news-date">
                  {{ formatDate(news.createTime, 'yyyy-MM-dd') }}
                </div>
              </div>
            </div>
            <div class="news-more" @click="$router.push('/kanfeiyi/list')">查看更多</div>
          </div>
        </div>
      </div>
      <!-- 新闻资讯-end -->
      <!-- 非遗项目-start -->
      <div class="section">
        <div class="box3">
          <!-- 随机高度 -->
          <!-- this.$router.replace({
          path: '/read',
          query: {
            id: this.content.bookId,
            chapter: item.chapterId,
            name: this.$route.query.name
          }
        }) -->
          <div class="project-item" v-for="(item, index) in projectCategorys" @click="$router.push({ path: '/shangfeiyi/list',query:{type:2 }})" :key="index"
            :style="{ height: randomFrom(30, 50) + '%' }">
            <div class="pro-cate-box">
              <img src="../../assets/images/project-icon.png" />
              <div class="pro-cate-name">{{ item.label }}</div>
              <img src="../../assets/images/project-icon.png" class="mirror-image" />
            </div>
          </div>
        </div>
      </div>
      <!-- 非遗项目-end -->
      <!-- 非遗传承人-start -->
      <div class="section ">
        <div class="box4">
          <div class="inheritor">
            <img src="https://oss.culturalcloud.net/yuhua/202310/23144659xdb2.png" alt="">
          </div>
          <div class="inheritor" style="transform: translateY(100px);">
            <img src="https://oss.culturalcloud.net/yuhua/202310/23145141d5kp.png" alt="">
          </div>
          <div class="inheritor" style="transform: translateY(-80px);">
            <img src="https://oss.culturalcloud.net/yuhua/202310/23145203ow8s.png" alt="">
          </div>
          <div class="inheritor" style="transform: translateY(0px);">
            <img src="https://oss.culturalcloud.net/yuhua/202310/231452164jyn.png" alt="">
          </div>
          <div class="inheritor" style="transform: translateY(-100px);">
            <img src="https://oss.culturalcloud.net/yuhua/202310/23145234epxw.png" alt="">
          </div>
        </div>
      </div>
      <!-- 非遗传承人-end -->
      <!-- 活动报名-start -->
      <div class="section">
        <div class="box5">
          <div class="left-banner" :style="{ backgroundImage: 'url(' + activityBanner + ')' }">
          </div>
          <div class="right-list">
            <div class="activity-item" @mouseenter="activityBannerChange(item)"
              :style="{ borderBottom: index == (activityList.length - 1) ? 'none' : '' }"
              v-for="(item, index) in activityList" :key="item.id" @click="$router.push('/wanfeiyi/detail/'+item.id)">
              <div class="activity-title-img">
                <img :src="item.titleImg" alt="">
              </div>
              <div class="activity-info">
                <div class="activity-title">
                  {{ item.title }}
                </div>
                <div class="activity-time">
                  报名时间：{{ formatDate(item.startTime, 'yyyy-MM-dd') + ' 至 ' + formatDate(item.endTime, 'yyyy-MM-dd') }}
                </div>
                <div class="activity-address">
                  活动地点：{{ item.address }}
                </div>
              </div>
            </div>
            <div class="activity-more-box" @click="$router.push('/wanfeiyi/list/')">
              <img src="../../assets/images/see-more.png" style="width: 90px;height: 20px;" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- 活动报名-end -->
      <!-- 非遗直播-start -->
      <div class="section">
        <div class="box6">
          <div class="live-box">
            <div class="live-banner" :style="{ backgroundImage: 'url(' + liveBanner.titleImg + ')' }" @click="$router.push('/bofeiyi/detail/'+liveBanner.id)" > 
              <div class="live-status" :style="{ backgroundColor: getLiveStatus()[1] }">
                {{ getLiveStatus()[0] }}
              </div>
              <div class="live-title">
                {{ liveBanner.title }}
              </div>
            </div>
            <div class="live-list">
              <div class="live-item" @mouseenter="liveBannerChange(item)" v-for="item in liveList" :key="item.id"  @click="$router.push('/bofeiyi/detail/'+item.id)">
                <img :src="item.titleImg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 非遗直播-end -->
      <!-- 非遗资源-start -->
      <div class="section">
        <div class="box7">
          <div class="resource" @click="$router.push('/xuefeiyi/videoList')">
            <div class="title-box">
              <div class="number">01</div>
              <div class="title">视频</div>
            </div>
            <div class="img-box">
              <img src="../../assets/images/resource-1.png" alt="">
            </div>
            <div class="see-more-box">
              <img src="../../assets/images/see-more.png" style="width: 90px;height: 20px;" alt="">
            </div>
          </div>
          <div class="resource" @click="$router.push('/xuefeiyi/videoList')">
            <div class="title-box">
              <div class="number">02</div>
              <div class="title">非遗作品</div>
            </div>
            <div class="img-box">
              <img src="../../assets/images/resource-2.png" alt="">
            </div>
            <div class="see-more-box">
              <img src="../../assets/images/see-more.png" style="width: 90px;height: 20px;" alt="">
            </div>
          </div>
          <div class="resource" @click="$router.push('/xuefeiyi/videoList')">
            <div class="title-box">
              <div class="number">03</div>
              <div class="title">音频</div>
            </div>
            <div class="img-box">
              <img src="../../assets/images/resource-3.png" alt="">
            </div>
            <div class="see-more-box">
              <img src="../../assets/images/see-more.png" style="width: 90px;height: 20px;" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- 非遗资源-end -->
    </full-page>
  </div>
</template>
 
<script>
import {
  getHomeLive
} from "@/api/live/live";
import {
  getHomeActivity
} from "@/api/activity/activity";
import {
  getAdvertList
} from "@/api/advert/advert";
import {
  getHomeNews
} from "@/api/news/news";
import {
  getHomeCategory
} from "@/api/project/project";
import RHeader from '@/components/Header.vue'
import {
  swiper,
  swiperSlide
} from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { start } from "nprogress";
export default {
  components: {
    RHeader,
    swiper,
    swiperSlide
  },
  data() {
    return {
      currentIndex: 0,
      options: {
        //字体是否随着窗口缩放而缩放
        resize: true,
        //页面滚动速度
        scrollingSpeed: 1000,
        //绑定菜单，设定相关属性和anchors的值对应后，菜单可以控制幻灯片滚动
        menu: "#myMenu",
        //定义锚链接，用户可以快速打开定位到某一页面；不需要加"#"，不要和页面中任意的id和name相同
        // anchors: ["page1", "page2", "page3", "page4"],
        //是否锁定锚链接
        // lockAnchors: true,
        //滚动到最顶部后是否连续滚动到底部
        // loopTop: true,
        //滚动到最底部后是否连续滚动到顶部
        loopBottom: true,
        //是否显示导航，设为true会显示小圆点作为导航
        navigation: true,
        scrollOverflow: true,
        navigationTooltips: ['', '非遗资讯', '非遗项目', '非遗传承人', '活动报名', '非遗直播', '非遗资源'],   //导航小圆点的提示
        showActiveTooltip: false,   //是否显示当前页面的tooltip信息
        //slidesNavigation: true,    //是否显示横向幻灯片的导航，默认为false
        //slidesNavPosition: 'bottom',   //横向导航的位置，默认为bottom，可以设置为top或bottom
        onLeave: (index, nextIndex, direction) => {
          this.currentIndex = nextIndex.index
        },
        afterLoad: function (anchorLink, index) {
          // console.log(index);
        },
      },
      swiperOption: {//swiper设置
        slidesPerView: 1,
        pagination: {// 设置分页器
          el: '.swiper-pagination',
          clickable: true// 设置点击可切换
        },
        autoplay: {// 设置自动轮播
          delay: 5000 // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true
      },
      activityBanner: '',//活动报名轮播图
      liveBanner: {},//直播轮播图
      adverts: [],//广告列表
      newsList: [],//资讯
      projectCategorys: [],//非遗项目分类
      activityList: [],//活动列表
      liveList: [],//直播列表
    };
  },
  created() {
    this.getAdverts()
    this.getNews()
    this.getProjectCategory()
    this.getActivityList()
    this.getLiveList()
  },
  methods: {
    toDetail(item){
      console.log(item);
      if(item.newWindow){
        window.open(item.link)
      }else {

      }
    },
    toNews(item){
      this.$router.push({path:'/kanfeiyi/detail/'+item.id})
      // console.log(item);
    },
    /**
     * 获取直播列表
     */
    getLiveList() {
      getHomeLive().then(res => {
        if (res.code == 0) {
          this.liveList = res.data
          this.liveBanner = this.liveList[0]
        }
      })
    },
    /**
     * 获取活动列表
     */
    getActivityList() {
      getHomeActivity().then(res => {
        if (res.code == 0) {
          this.activityList = res.data
          this.activityBanner = this.activityList[0].titleImg
        }
      })
    },
    /**
     * 获取非遗项目分类
     */
    getProjectCategory() {
      getHomeCategory().then(res => {
        if (res.code == 0) {
          this.projectCategorys = res.data
        }
      })
    },
    /**
     * 获取首页资讯
     */
    getNews() {
      getHomeNews().then(res => {
        if (res.code == 0) {
          this.newsList = res.data
        }
      })
    },
    /**
     * 获取广告列表
     */
    getAdverts() {
      getAdvertList().then(res => {
        if (res.code == 0) {
          this.adverts = res.data
        }
      })
    },
    /**
     * 获取直播状态
     */
    getLiveStatus() {
      let startTime = this.liveBanner.startTime
      let endTime = this.liveBanner.endTime
      let now = new Date().getTime()
      if (now < startTime) {
        return ['即将开始', '#FFCD00']
      } else if (now > startTime && now < endTime) {
        return ['直播中', '#ff8b8b']
      } else {
        return ['已结束', '#979797']
      }
    },
    /**
     * 修改活动报名图片
     * @param {object} item 
     */
    activityBannerChange(item) {
      this.activityBanner = item.titleImg
    },
    /**
     * 修改直播图片
     * @param {object} item 
     */
    liveBannerChange(item) {
      this.liveBanner = item
    },
    /**
     * 取指定区间内的随机数
     * @param {*} lowerValue 最小值
     * @param {*} upperValue 最大值
     */
    randomFrom(lowerValue, upperValue) {
      return Math.floor(Math.random() * (upperValue - lowerValue + 1) + lowerValue);
    }
  },
  mounted() {

  }
};
</script>
<style scoped>
.swiper-pagination-bullet {
  width: 40px;
  height: 25px;
  background: url('../../assets/images/swiper-pagination.png');
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: url('../../assets/images/swiper-pagination-active.png');
}
</style>
 
<style scoped lang="scss">
li {
  list-style: none;
}

.activeColor {
  border-bottom: 2px solid #fff;
}

.w {
  width: 1200px;
  margin: 0 auto;
}

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

#myMenu {
  height: 50px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 300px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#myMenu li a {
  color: #000;
}

#myMenu li {
  margin-right: 20px;
  padding: 12px;
}

.home-container {
  .home-swiper {
    width: 100%;
    height: calc(100% - 80px);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .box2 {
    width: 100%;
    height: 100%;
    padding: 0 100px;
    background: url('https://oss.culturalcloud.net/yuhua/202310/21221533ob49.png') no-repeat center center scroll;
    background-size: cover;
    display: flex;
    justify-content: space-around;

    .news-box {
      
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 410px;
      height: 100%;
      background-color: rgba(65, 123, 126, 0.6);
      box-sizing: border-box;
      padding: 80px 30px;

      .news-box-title {
       
        width: 100%;
        height: 100px;
        background: url(../../assets/images/news-title-bg.png) no-repeat center center scroll;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #EED263;
        font-weight: bold;
      }

      .news-list {
        cursor: pointer;
        display: flex;
        flex-direction: column;

        div {
          margin: 15px 0;
        }

        .a-line {
          display: flex;
          flex-wrap: wrap;

          .news-title {
            width: 70%;
          }

          .news-date {
            width: 30%;
          }
        }

        .news-item {
          border-bottom: 1px dashed #EED263;

          .title-img {
            width: 100%;
            height: 245px;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #EED263;

            img {
              width: 100%;
              height: 100%;
            }
          }



          .news-title {
            color: #ffffff;
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .news-description {
            color: #9EBBBD;
            font-size: 14px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .news-date {
            color: #ffffff;
            font-size: 16px;
          }
        }
      }

      .news-more {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px auto 0;
        color: #EED263;
        width: 100px;
        height: 37px;
        background: url(../../assets/images/news-more.png) no-repeat center center scroll;
      }

    }

  }

  .box3 {
    width: 100%;
    height: 100%;
    padding: 0 15%;
    background: url('https://oss.culturalcloud.net/yuhua/202310/23114236movm.png') no-repeat center center scroll;
    background-size: cover;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;

    .project-item:hover {
      border-color: #D1003E;

      img {
        content: url(../../assets/images/project-icon-red.png) !important;
      }


      .pro-cate-name {
        color: #D1003E;
      }
    }

    .project-item {
      transition: .3s;
      width: 2px;
      border-left: 1.5px dashed #6E4200;
      position: relative;
      display: flex;
      flex-direction: column;

      .pro-cate-name {
        transition: .3s;
        width: 100%;
        writing-mode: vertical-rl;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #6E4200;
        margin: 5px 0 8px;
      }

      .pro-cate-box {
        cursor: pointer;
        width: 30px;
        position: absolute;
        transform: translate(-50%, -100%);

        img {
          transition: .5s;
        }

        .mirror-image {
          transform: rotate(180deg);
        }
      }

    }

  }

  .box4 {
    width: 100%;
    height: 100%;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url('https://oss.culturalcloud.net/yuhua/202310/23114458c62p.png') no-repeat center center scroll;
    background-size: cover;

    .inheritor {
      box-sizing: content-box;
      padding: 10px;
      border: 1px solid #AA8E56;
      border-radius: 8px;
    }
  }

  .box5 {
    width: 100%;
    height: 100%;
    display: flex;
    background: url('https://oss.culturalcloud.net/yuhua/202310/24165956ewfc.png') no-repeat center center scroll;
    background-size: cover;
    justify-content: space-between;
    align-items: center;

    .left-banner {
      transition: .3s;
      background-size: cover;
      background-position: center center;
    }

    .left-banner,
    .right-list {
      height: 100%;
      width: 48%;
    }


    .right-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 80px;

      .activity-more-box {
        width: 600px;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }



      .activity-item {
        cursor: pointer;
        width: 600px;
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px dashed #EED263;

        .activity-title-img {
          transition: .3s;
          width: 230px;
          height: 170px;
          padding: 5px;
          border: 1px solid #A5B6B8;
          border-radius: 8px;
          box-shadow: 5px 5px 15px rgba(58, 95, 94, 1);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .activity-info {
          width: calc(100% - 230px - 30px);
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .activity-title {
            transition: .3s;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: bold;
          }

          .activity-time,
          .activity-address {
            font-size: 14px;
            color: #A5B6B8;
          }

        }
      }


      .activity-item:hover .activity-title-img {
        border: 1px solid #EED263;
      }

      .activity-item:hover .activity-title {
        color: #EED263;
      }

    }

  }

  .box6 {
    width: 100%;
    height: 100%;
    display: flex;
    background: url('https://oss.culturalcloud.net/yuhua/202310/24224139ueyh.png') no-repeat center center scroll;
    background-size: cover;
    justify-content: center;
    align-items: center;

    .live-box {
      width: 1200px;
      height: 540px;
      display: flex;
      justify-content: space-between;

      .live-banner::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        pointer-events: none;
        background: url('../../assets/images/play.png') no-repeat center center, ;
        background-color: rgba(0, 0, 0, .35);
      }

      .live-banner {
        width: 900px;
        height: 100%;
        border-radius: 18px;
        overflow: hidden;
        position: relative;
        transition: 0.1s;
        background-size: cover;
        background-position: center center;

        .live-status {
          width: 100px;
          height: 35px;
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          font-size: 18px;
          position: absolute;
          top: 20px;
          left: 20px;
          z-index: 99;
        }

        .live-title {
          position: absolute;
          z-index: 100;
          bottom: 0;
          left: 0;
          width: 100%;
          font-size: 24px;
          padding: 20px 20px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .live-list {
      width: calc(100% - 900px - 30px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .live-item {
        width: 100%;
        height: 32%;
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .box7 {
    width: 100%;
    height: 100%;
    display: flex;
    background: url('https://oss.culturalcloud.net/yuhua/202310/24233133jhon.png') no-repeat center center scroll;
    background-size: cover;
    justify-content: space-between;
    align-items: flex-end;
    padding: 3% 10%;

    .resource {
      display: flex;
      flex-direction: column;

      .title-box {
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .number {
          font-size: 64px;
          color: #FFFFFF;
        }

        .title {
          font-size: 20px;
          color: #FFFFFF;
        }
      }

      .img-box {
        width: 335px;
        height: 260px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, .6);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .see-more-box {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>


